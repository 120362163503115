import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApiError, ApiFetchStatus } from "../../utils/Api"


export enum OrganizationRole {
  ADMIN = 'admin',
  USER = 'user',
  EXTERNAL = 'external',
}
export interface OrganisationUser {
  email    : string
  firstName: string
  lastName : string
  lang     : string
  organizationRole: OrganizationRole
  groups: string[]
  accessTeam: boolean
  accessMeetings: boolean
  accessPlanning: boolean
  accessDrive: boolean
  accessOrganization: boolean
}


interface State {
  usersTotalNumber: number
  users: OrganisationUser[]
  fetchUsersStatus: ApiFetchStatus
  fetchUsersError?: string
  fetchUnattachedUsersStatus: ApiFetchStatus
  fetchUnattachedUsersError?: string
  fetchCreateUserStatus: ApiFetchStatus
  fetchCreateUserError?: string
  fetchAttachUserStatus: ApiFetchStatus
  fetchAttachUserError?: string
  fetchModifyUserStatus: ApiFetchStatus
  fetchModifyUserError?: string
  fetchDetachUserStatus: ApiFetchStatus
  fetchDetachUserError?: string
}


const initialState: State = {
  usersTotalNumber: 0,
  users: [],
  fetchUsersStatus: "idle",
  fetchUnattachedUsersStatus: "idle",
  fetchCreateUserStatus: "idle",
  fetchAttachUserStatus: "idle",
  fetchModifyUserStatus: "idle",
  fetchDetachUserStatus: "idle",
}


export const fetchUsers = createAsyncThunk(
  'organizationUsers/fetchUsers',
  async ({ organizationId, page }: { organizationId: string, page: number }, { getState }) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/users?page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${state.auth.token}`,
      },
    })
    
    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    return await response.json()
  }
)

export const fetchUnattachedUsers = createAsyncThunk(
  'organizationUsers/fetchUnattachedUsers',
  async ({ page }: { page: number }, { getState }) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/unattached_users?page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${state.auth.token}`,
      },
    })
    
    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    return await response.json()
  }
)


export const fetchCreateUser = createAsyncThunk(
  'organizationUsers/fetchCreateUser',
  async (
    { organizationId, user }: { organizationId: string, user: OrganisationUser }, 
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/user/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
      body: JSON.stringify(user),
    })
    
    if (!response.ok) {
      const reason = (await response.json()).message || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


export const fetchModifyUser = createAsyncThunk(
  'organizationUsers/fetchModifyUser',
  async (
    { organizationId, originalEmail, user }: { organizationId: string, originalEmail: string, user: OrganisationUser },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/user/modify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
      body: JSON.stringify({
        originalEmail,
        ...user
      }),
    })
    
    if (!response.ok) {
      const reason = (await response.json()).message || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


export const fetchAttachUser = createAsyncThunk(
  'organizationUsers/fetchAttachUser',
  async (
    { organizationId, user }: 
      {
        organizationId: string
        user: { email: string }
      },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/attach_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
      body: JSON.stringify(user),
    })
    
    if (!response.ok) {
      const reason = (await response.json()).message || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


export const fetchDetachUser = createAsyncThunk(
  'organizationUsers/fetchDetachUser',
  async (
    { organizationId, user }: 
      {
        organizationId: string
        user: { email: string }
      },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/detach_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
      body: JSON.stringify(user),
    })
    
    if (!response.ok) {
      throw new Error()
    }
  }
)


const organizationUsersSlice = createSlice({
  name: 'organizationUsers',
  initialState,
  reducers: {
    idleUsers: (state) => {
      state.fetchUsersStatus = "idle"
      state.fetchUsersError = undefined
      state.fetchUnattachedUsersStatus = "idle"
      state.fetchUnattachedUsersError = undefined
      state.fetchCreateUserStatus = "idle"
      state.fetchCreateUserError = undefined
      state.fetchModifyUserStatus = "idle"
      state.fetchModifyUserError = undefined
      state.fetchAttachUserStatus = "idle"
      state.fetchAttachUserError = undefined
      state.fetchDetachUserStatus = "idle"
      state.fetchDetachUserError = undefined
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.fetchUsersStatus = "loading"
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }: PayloadAction<{ users: OrganisationUser[], totalNumber: number }>) => {
        state.fetchUsersStatus = "success"
        state.users = payload.users
        state.usersTotalNumber = payload.totalNumber
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.fetchUsersStatus = "error"
      })
      .addCase(fetchUnattachedUsers.pending, (state) => {
        state.fetchUnattachedUsersStatus = "loading"
      })
      .addCase(fetchUnattachedUsers.fulfilled, (state, { payload }: PayloadAction<{ users: OrganisationUser[], totalNumber: number }>) => {
        state.fetchUnattachedUsersStatus = "success"
        state.users = payload.users
        state.usersTotalNumber = payload.totalNumber
      })
      .addCase(fetchUnattachedUsers.rejected, (state) => {
        state.fetchUnattachedUsersStatus = "error"
      })
      .addCase(fetchCreateUser.pending, (state) => {
        state.fetchCreateUserStatus = "loading"
      })
      .addCase(fetchCreateUser.fulfilled, (state) => {
        state.fetchCreateUserStatus = "success"
      })
      .addCase(fetchCreateUser.rejected, (state, action) => {
        state.fetchCreateUserStatus = "error"
        state.fetchCreateUserError = action.error.message
      })
      .addCase(fetchModifyUser.pending, (state) => {
        state.fetchModifyUserStatus = "loading"
      })
      .addCase(fetchModifyUser.fulfilled, (state) => {
        state.fetchModifyUserStatus = "success"
      })
      .addCase(fetchModifyUser.rejected, (state, action) => {
        state.fetchModifyUserStatus = "error"
        state.fetchModifyUserError = action.error.message
      })
      .addCase(fetchAttachUser.pending, (state) => {
        state.fetchAttachUserStatus = "loading"
      })
      .addCase(fetchAttachUser.fulfilled, (state) => {
        state.fetchAttachUserStatus = "success"
      })
      .addCase(fetchAttachUser.rejected, (state, action) => {
        state.fetchAttachUserStatus = "error"
        state.fetchAttachUserError = action.error.message
      })
      .addCase(fetchDetachUser.pending, (state) => {
        state.fetchDetachUserStatus = "loading"
      })
      .addCase(fetchDetachUser.fulfilled, (state) => {
        state.fetchDetachUserStatus = "success"
      })
      .addCase(fetchDetachUser.rejected, (state, action) => {
        state.fetchDetachUserStatus = "error"
        state.fetchDetachUserError = action.error.message
      })
  }
})

export const {
  idleUsers
} = organizationUsersSlice.actions

export default organizationUsersSlice.reducer
